<template>
  <div>
    <el-button class="aaa" @click="back">未到找指定页面</el-button>
  </div>
</template>

<script>
export default {
  name: 'error',
  data() {
    return {}
  },
  methods: {
    back() {
      this.$router.back()
    },
  },
}
</script>

<style scoped>
div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f8f5ec;
  display: flex;
  justify-content: center;
}
img {
  display: block;
  margin: auto;
}
p {
  font-size: 40px;
  text-align: center;
  margin-bottom: 100px;
}
.back {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
}

.aaa {
  height: 50px;
  margin-top: 10%;
}
</style>
